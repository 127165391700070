// ==============================
// Custom style
// 自定义样式
// ==============================

//ads
.ads-container {
    max-width: 100%;
    width: 100%;
    margin: 0 auto; /* 居中显示 */
    overflow: hidden;
}

.adsbygoogle {
    max-width: 100% !important; /* 强制广告宽度为100% */
    height: auto !important;    /* 保证高度自适应 */
    display: block;
    margin: 0 auto;
}
