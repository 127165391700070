// ==============================
// Custom style
// 自定义样式
// ==============================

//ads
.ads-container {
    max-width: 100%;
    text-align: center;
    overflow: hidden;
}